import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders,  HttpResponse, HttpErrorResponse} from '@angular/common/http';
import { map } from 'rxjs/operators';
import {GLOBAL} from './global';
import { Cliente } from 'src/app/models/cliente';
import { Facturacion } from 'src/app/models/facturacion';
import { PotenciaConsumida } from '../models/potenciaConsumida';
import { Consumo } from '../models/consumo';
import { Potencia } from '../models/potencia';
import { Varios } from '../models/varios';
import { Fechas } from '../models/fechas';
import { Precio } from '../models/precio';
import { General } from '../models/general';
import { Tarifas } from '../models/tarifas';

@Injectable()
export class GeneralService{
    public url: string;

    constructor(public _http:HttpClient){
        this.url=GLOBAL.url;
    }

    //--------------------------------------------------------------------------------------------------------------
    //CLIENTES

    buscarClientes(): Observable<any>{
        return this._http.get(this.url+'/datosCliente/search');    
    }

    buscarClientesPorId(id): Observable<any>{
        return this._http.get(this.url+'/datosCliente/search/'+id);
    }
    
    addCliente(usuario:Cliente): Observable<any>{
        return this._http.post(this.url+'/datosCliente/add',usuario);
    }

    editCliente(id, cliente:Cliente):Observable<any>{
        return this._http.post(this.url+'/datosCliente/update/'+id, cliente);
    }

    deleteCliente(id){
        return this._http.get(this.url+'/datosCliente/delete/'+id);
    }

    //--------------------------------------------------------------------------------------------------------------
    //Facturacion

    buscarFacturacion(): Observable<any>{
        return this._http.get(this.url+'/datosFactura/search');    
    }

    buscarFacturacionPorId(id): Observable<any>{
        return this._http.get(this.url+'/datosFactura/search/'+id);
    }
    
    addFacturacion(factura:Facturacion): Observable<any>{
        return this._http.post(this.url+'/datosFactura/add',factura);
    }

    editFacturacion(id, factura:Facturacion):Observable<any>{
        return this._http.post(this.url+'/datosFactura/update/'+id, factura);
    }

    deleteFacturacion(id){
        return this._http.get(this.url+'/datosFactura/delete/'+id);
    }

    //--------------------------------------------------------------------------------------------------------------
    //Potencia Consumida

    buscarPotenciaConsumida(): Observable<any>{
        return this._http.get(this.url+'/datosPotenciaConsumida/search');    
    }

    buscarPotenciaConsumidaPorId(id): Observable<any>{
        return this._http.get(this.url+'/datosPotenciaConsumida/search/'+id);
    }

    buscarPotenciaConsumidaPorIdCliente(id): Observable<any>{
        return this._http.get(this.url+'/datosPotenciaConsumida/searchCliente/'+id);
    }
    
    addPotenciaConsumida(pConsumida:PotenciaConsumida): Observable<any>{
        return this._http.post(this.url+'/datosPotenciaConsumida/add',pConsumida);
    }

    editPotenciaConsumida(id, pConsumida:PotenciaConsumida):Observable<any>{
        return this._http.post(this.url+'/datosPotenciaConsumida/update/'+id, pConsumida);
    }

    deletePotenciaConsumida(id){
        return this._http.get(this.url+'/datosPotenciaConsumida/delete/'+id);
    }

    //--------------------------------------------------------------------------------------------------------------
    //Consumo

    buscarConsumo(): Observable<any>{
        return this._http.get(this.url+'/datosConsumo/search');    
    }

    buscarConsumoPorId(id): Observable<any>{
        return this._http.get(this.url+'/datosConsumo/search/'+id);
    }
    
    addConsumo(consumo:Consumo): Observable<any>{
        return this._http.post(this.url+'/datosConsumo/add',consumo);
    }

    editConsumo(id, consumo:Consumo):Observable<any>{
        return this._http.post(this.url+'/datosConsumo/update/'+id, consumo);
    }

    deleteConsumo(id){
        return this._http.get(this.url+'/datosConsumo/delete/'+id);
    }

    //--------------------------------------------------------------------------------------------------------------
    //Potencia

    buscarPotencia(): Observable<any>{
        return this._http.get(this.url+'/datosPotencia/search');    
    }

    buscarPotenciaPorId(id): Observable<any>{
        return this._http.get(this.url+'/datosPotencia/search/'+id);
    }

    buscarPotenciaPorIdCliente(id): Observable<any>{
        return this._http.get(this.url+'/datosPotencia/searchCliente/'+id);
    }
    
    addPotencia(potencia:Potencia): Observable<any>{
        return this._http.post(this.url+'/datosPotencia/add',potencia);
    }

    editPotencia(id, potencia:Potencia):Observable<any>{
        return this._http.post(this.url+'/datosPotencia/update/'+id, potencia);
    }

    deletePotencia(id){
        return this._http.get(this.url+'/datosPotencia/delete/'+id);
    }

    //--------------------------------------------------------------------------------------------------------------
    //Varios

    buscarVarios(): Observable<any>{
        return this._http.get(this.url+'/datosVarios/search');    
    }

    buscarVariosPorId(id): Observable<any>{
        return this._http.get(this.url+'/datosVarios/search/'+id);
    }
    
    addVarios(varios:Varios): Observable<any>{
        return this._http.post(this.url+'/datosVarios/add',varios);
    }

    editVarios(id, varios:Varios):Observable<any>{
        return this._http.post(this.url+'/datosVarios/update/'+id, varios);
    }

    deleteVarios(id){
        return this._http.get(this.url+'/datosVarios/delete/'+id);
    }

    //--------------------------------------------------------------------------------------------------------------
    //Fechas

    buscarFechas(): Observable<any>{
        return this._http.get(this.url+'/datosFechas/search');    
    }

    buscarFechasPorId(id): Observable<any>{
        return this._http.get(this.url+'/datosFechas/search/'+id);
    }
    
    addFechas(fechas:Fechas): Observable<any>{
        return this._http.post(this.url+'/datosFechas/add',fechas);
    }

    editFechas(id, fechas:Fechas):Observable<any>{
        return this._http.post(this.url+'/datosFechas/update/'+id, fechas);
    }

    deleteFechas(id){
        return this._http.get(this.url+'/datosFechas/delete/'+id);
    }

    //--------------------------------------------------------------------------------------------------------------
    //Precio

    buscarPrecio(): Observable<any>{
        return this._http.get(this.url+'/datosPrecio/search');    
    }

    buscarPrecioPorId(id): Observable<any>{
        return this._http.get(this.url+'/datosPrecio/search/'+id);
    }
    
    addPrecio(precio:Precio): Observable<any>{
        return this._http.post(this.url+'/datosPrecio/add',precio);
    }

    editPrecio(id, precio:Precio):Observable<any>{
        return this._http.post(this.url+'/datosPrecio/update/'+id, precio);
    }

    deletePrecio(id){
        return this._http.get(this.url+'/datosPrecio/delete/'+id);
    }

    //--------------------------------------------------------------------------------------------------------------
    //General

    buscarGeneral(): Observable<any>{
        return this._http.get(this.url+'/datosGeneral/search');    
    }
    
    addGeneral(General:General): Observable<any>{
        return this._http.post(this.url+'/datosGeneral/add',General);
    }

    editGeneral(id, General:General):Observable<any>{
        return this._http.post(this.url+'/datosGeneral/update/'+id, General);
    }

    deleteGeneral(id){
        return this._http.get(this.url+'/datosGeneral/delete/'+id);
    }

    
    //--------------------------------------------------------------------------
    //Searchs General
    buscarGeneralPorId(id): Observable<any>{
        return this._http.get(this.url+'/datosGeneral/search/'+id);
    }

    buscarGeneralPorIdCliente(id): Observable<any>{
        return this._http.get(this.url+'/datosGeneral/searchCliente/'+id);
    }

    buscarGeneralPorIdFacturacion(id): Observable<any>{
        return this._http.get(this.url+'/datosGeneral/searchFacturacion/'+id);
    }

    buscarGeneralPorIdPotenciaConsumida(id): Observable<any>{
        return this._http.get(this.url+'/datosGeneral/searchPotenciaConsumida/'+id);
    }

    buscarGeneralPorIdConsumo(id): Observable<any>{
        return this._http.get(this.url+'/datosGeneral/searchConsumo/'+id);
    }

    buscarGeneralPorIdPotencia(id): Observable<any>{
        return this._http.get(this.url+'/datosGeneral/searchPotencia/'+id);
    }

    buscarGeneralPorIdVarios(id): Observable<any>{
        return this._http.get(this.url+'/datosGeneral/searchVarios/'+id);
    }

    buscarGeneralPorIdFechas(id): Observable<any>{
        return this._http.get(this.url+'/datosGeneral/searchFecha/'+id);
    }

    buscarGeneralPorIdPrecio(id): Observable<any>{
        return this._http.get(this.url+'/datosGeneral/searchPrecio/'+id);
    }

    //--------------------------------------------------------------------------------------------------------------
    //TARIFAS

    buscarTarifa(): Observable<any>{
        return this._http.get(this.url+'/datosTarifa/search');    
    }

    buscarTarifaPorId(id): Observable<any>{
        return this._http.get(this.url+'/datosTarifa/search/'+id);
    }
    
    addTarifa(tarifas:Tarifas): Observable<any>{
        return this._http.post(this.url+'/datosTarifa/add',tarifas);
    }

    editTarifa(id, tarifas:Tarifas):Observable<any>{
        return this._http.post(this.url+'/datosTarifa/update/'+id, tarifas);
    }

    deleteTarifa(id){
        return this._http.get(this.url+'/datosTarifa/delete/'+id);
    }
}