import { Component, OnInit } from '@angular/core';
import * as html2pdf from 'html2pdf.js';
import { ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { GeneralService } from '../services/general.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

//models
import { Cliente } from 'src/app/models/cliente';
import { Facturacion } from 'src/app/models/facturacion';
import { PotenciaConsumida } from 'src/app/models/potenciaConsumida';
import { Consumo } from 'src/app/models/consumo';
import { Potencia } from 'src/app/models/potencia';
import { Varios } from 'src/app/models/varios';
import { Fechas } from 'src/app/models/fechas';
import { Precio } from 'src/app/models/precio';
import { General } from 'src/app/models/general';
import { Tarifas } from 'src/app/models/tarifas';

@Component({
  selector: 'app-exportar-pdf',
  templateUrl: './exportar-pdf.component.html',
  styleUrls: ['./exportar-pdf.component.scss'],
  providers: [GeneralService]
})
export class ExportarPdfComponent {
  public general: General;
  public cliente: Cliente;
  public factura: Facturacion;
  public fecha: Fechas;
  public precio: Precio;
  public precioFormato;
  public consumo: Consumo;
  public potencia: Potencia;
  public varios: Varios;
  public precioIVa;
  public consumoDiario;

  constructor(private _route: ActivatedRoute, private _router: Router, private _crmService: GeneralService) { }

  numberWithSeparator(x) {
    return parseFloat(x).toLocaleString('de-DE', {minimumFractionDigits: 2});
    // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  //metodo para exportar a pdf la factura en cuestión
  exportPdf() {
    const options = {
      filename: "FACTURA " + this.cliente[0].codigo + "-" + this.factura[0].numero_factura + " " + this.cliente[0].nombre + ".pdf",
      imagen: { type: 'jpeg', quality: 0.98 },
      html2canvas: {},
      margin: [0, 10, 0, 10],
      jsPDF: { format: 'a4', orientation: 'portrait' }
    };

    const content: Element = document.getElementById('pdf');

    html2pdf().from(content).set(options).save();
  }

  //eventos para comprobar el funcionamiento de la grafica circular
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  //metodos para recoger todos los datos a raiz de la tabla general
  getGeneral() {
    this._route.params.forEach((params: Params) => {
      let id = params['id'];
      this._crmService.buscarGeneralPorId(id).subscribe(
        result => {
          this.general = result;
          this.getCliente(this.general[0].idCliente);
          this.getFactura(this.general[0].idCliente);
          this.getFechas(this.general[0].fechas, this.general[0].consumo);
          this.getPrecio(this.general[0].precio);
          this.getPotencia(this.general[0].potencia);
          this.getVarios(this.general[0].varios);
        },
        error => {
          console.log(<any>error);
        }
      );
    });
  }

  getCliente(id) {
    this._crmService.buscarClientesPorId(id).subscribe(
      result => {
        this.cliente = result;
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  getFactura(id) {
    this._crmService.buscarFacturacionPorId(id).subscribe(
      result => {
        this.factura = result;
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  getPotencia(id) {
    this._crmService.buscarPotenciaPorId(id).subscribe(
      result => {
        this.potencia = result;
        // console.log(result[0]);

        this.potencia[0].potencia1 = this.numberWithSeparator(this.potencia[0].potencia1);
        this.potencia[0].potencia2 = this.numberWithSeparator(this.potencia[0].potencia2);
        this.potencia[0].potencia3 = this.numberWithSeparator(this.potencia[0].potencia3);
        this.potencia[0].potencia4 = this.numberWithSeparator(this.potencia[0].potencia4);
        this.potencia[0].potencia5 = this.numberWithSeparator(this.potencia[0].potencia5);
        this.potencia[0].potencia6 = this.numberWithSeparator(this.potencia[0].potencia6);

      },
      error => {
        console.log(<any>error);
      }
    );
  }

  getVarios(id) {
    this._crmService.buscarVariosPorId(id).subscribe(
      result => {
        this.varios = result;
        // console.log(this.varios[0])
        this.varios[0].reactiva = this.numberWithSeparator(this.varios[0].reactiva);
        this.varios[0].potencia_total_euros = this.numberWithSeparator(this.varios[0].potencia_total_euros);
        this.varios[0].exc_potencia = this.numberWithSeparator(this.varios[0].exc_potencia);
        this.varios[0].contador_precio = this.numberWithSeparator(this.varios[0].contador_precio);
        this.varios[0].otros = this.numberWithSeparator(this.varios[0].otros);
        this.varios[0].iee = this.numberWithSeparator(this.varios[0].iee);

      },
      error => {
        console.log(<any>error);
      }
    );
  }

  getFechas(id, idConsumo) {
    this._crmService.buscarFechasPorId(id).subscribe(
      result => {
        this.fecha = result;
        this.fecha[0].fecha_factura = this.fecha[0].fecha_factura.slice(8,10) + '/' + this.fecha[0].fecha_factura.slice(5,7) + '/' + this.fecha[0].fecha_factura.slice(0,4);
        this.fecha[0].fecha_cargo = this.fecha[0].fecha_cargo.slice(8,10) + '/' + this.fecha[0].fecha_cargo.slice(5,7) + '/' + this.fecha[0].fecha_cargo.slice(0,4);
        this.fecha[0].periodo_final = this.fecha[0].periodo_final.slice(8,10) + '/' + this.fecha[0].periodo_final.slice(5,7) + '/' + this.fecha[0].periodo_final.slice(0,4);
        this.fecha[0].periodo_inicio = this.fecha[0].periodo_inicio.slice(8,10) + '/' + this.fecha[0].periodo_inicio.slice(5,7) + '/' + this.fecha[0].periodo_inicio.slice(0,4);
        this.getConsumo(idConsumo);
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  getConsumo(id) {
    this._crmService.buscarConsumoPorId(id).subscribe(
      result => {
        this.consumo = result;
        // console.log(result[0])
        let p1:number = +(this.consumo[0].consumo1*100)/this.consumo[0].consumoTotal;
        let p2:number = +(this.consumo[0].consumo2*100)/this.consumo[0].consumoTotal;
        let p3:number = +(this.consumo[0].consumo3*100)/this.consumo[0].consumoTotal;
        let p4:number = +(this.consumo[0].consumo4*100)/this.consumo[0].consumoTotal;
        let p5:number = +(this.consumo[0].consumo5*100)/this.consumo[0].consumoTotal;
        let p6:number = +(this.consumo[0].consumo6*100)/this.consumo[0].consumoTotal;

        this.consumo[0].consumo1 = this.numberWithSeparator(this.consumo[0].consumo1);
        this.consumo[0].consumo2 = this.numberWithSeparator(this.consumo[0].consumo2);
        this.consumo[0].consumo3 = this.numberWithSeparator(this.consumo[0].consumo3);
        this.consumo[0].consumo4 = this.numberWithSeparator(this.consumo[0].consumo4);
        this.consumo[0].consumo5 = this.numberWithSeparator(this.consumo[0].consumo5);
        this.consumo[0].consumo6 = this.numberWithSeparator(this.consumo[0].consumo6);

        
        this.pieChartData = [p1, p2, p3, p4, p5, p6];
        this.consumoPorDia();
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  getPrecio(id) {
    this._crmService.buscarPrecioPorId(id).subscribe(
      result => {
        this.precio = result;
        this.precioIVa = +((this.precio[0].cuota_fija * 21) / 100).toPrecision(4);
        this.precioIVa = this.numberWithSeparator(this.precioIVa);
        this.precio[0].cuota_fija = this.numberWithSeparator(this.precio[0].cuota_fija);
        this.precio[0].precioTotal = this.numberWithSeparator(this.precio[0].precioTotal);
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  consumoPorDia() {
    this.consumoDiario = +((this.consumo[0].consumoTotal / this.fecha[0].dias)).toPrecision(3);
    this.consumo[0].consumoTotal = this.numberWithSeparator(this.consumo[0].consumoTotal);
    this.consumoDiario = this.numberWithSeparator(this.consumoDiario);

  }

  //codigo necesario para el funcionamiento de la grafica circular
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'left',
    },
    plugins: {
      datalabels: {
        formatter: (dato) => 
        dato.toFixed(0) + "%",
        color: "#000"
      }
    }
  };

  public pieChartLabels: Label[] = [['1'], ['2'], ['3'], ['4'], ['5'], ['6']];
  public pieChartData: number[] = [0, 0, 0, 0, 0, 0];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(18, 195, 216, 0.3)', 'rgba(219, 219, 219, 0.3)', 'rgba(216, 210, 18, 0.3)', 'rgba(172, 115, 185, 0.3)', 'rgba(21, 191, 57, 0.3)'],
    },
  ];

  ngOnInit(): void {
    this.getGeneral();
  }

}
