import { Component, OnInit } from '@angular/core';
import * as html2pdf from 'html2pdf.js';
import { ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { GeneralService } from '../services/general.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

//models
import { Cliente } from 'src/app/models/cliente';
import { Facturacion } from 'src/app/models/facturacion';
import { PotenciaConsumida } from 'src/app/models/potenciaConsumida';
import { Consumo } from 'src/app/models/consumo';
import { Potencia } from 'src/app/models/potencia';
import { Varios } from 'src/app/models/varios';
import { Fechas } from 'src/app/models/fechas';
import { Precio } from 'src/app/models/precio';
import { General } from 'src/app/models/general';
import { Tarifas } from 'src/app/models/tarifas';
import { consumoDiario } from '../models/consumoTotal';

@Component({
  selector: 'app-export-pdf-duplicate',
  templateUrl: './export-pdf-duplicate.component.html',
  styleUrls: ['./export-pdf-duplicate.component.scss'],
  providers: [GeneralService]
})
export class ExportPdfDuplicateComponent implements OnInit {
  //general
  public general: General;
  public generales:Array <General>=[];
  public numeroFactura;
  //cliente
  public cliente: Cliente;
  public clientes:Array <Cliente>=[];
  //factura
  public factura: Facturacion;
  public facturas:Array <Facturacion>=[];
  //fecha
  public fecha: Fechas;
  public fechas:Array<Fechas>=[];
  //precio
  public precio: Precio;
  public precios:Array<Precio> =[];
  //consumo
  public consumo: Consumo;
  public consumos:Array<Consumo>=[];
  //potencia
  public potencia: Potencia;
  public potencias:Array<Potencia>=[];
  //precioPotencia
  public potenciaConsumida: PotenciaConsumida;
  public potenciasConsumidas:Array<PotenciaConsumida>=[];
  //varios
  public varios: Varios;
  public variosAll:Array<Varios>=[];
  public precioIVa;
  public consumoDiario:Array<consumoDiario>=[];
  //precios
  public cuotaFinal:number = 0;
  public ivaFinal:number = 0;
  public importeFinal:number = 0;

  constructor(private _route: ActivatedRoute, private _router: Router, private _crmService: GeneralService) { }

  //metodo para exportar a pdf la factura en cuestión
  exportPdf() {
    const options = {
      filename: "FACTURA " + this.cliente.codigo + "-" + this.factura.numero_factura + " " + this.cliente.nombre + ".pdf",
      imagen: { type: 'jpeg', quality: 0.98 },
      html2canvas: {},
      margin: [0, 10, 0, 10],
      jsPDF: { format: 'a4', orientation: 'portrait' }
    };

    const content: Element = document.getElementById('pdf');

    html2pdf().from(content).set(options).save();
  }

  //eventos para comprobar el funcionamiento de la grafica circular
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  //metodos para recoger todos los datos a raiz de la tabla general
  getGeneral() {
    this._crmService.buscarGeneral().subscribe(
      result => {
        var ids = JSON.parse(localStorage.getItem('arrayIds'));
        console.log(ids);
        let contador=0;
        for(let i=0; i<result.length; i++){
          for(let j=0; j<ids.length; j++){
            if(result[i].idGeneral == ids[j]){
              this.generales[contador]=result[i];
              this.numeroFactura = this.generales[contador].numero_factura;
              contador++;
            }
          }
        }
        this.getFacturas(this.generales);
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  getFacturas(generalFiltrado){
    this._crmService.buscarFacturacion().subscribe(
      result => {
        let contador=0;
        for(let i=0; i<result.length; i++){
          for(let j=0; j<generalFiltrado.length; j++){
            if(result[i].idFactura == generalFiltrado[j].factura){
              this.facturas[contador]=result[i];
              contador++;
            }
          }
        }
        this.factura=this.facturas[0];
        this.getClientes(generalFiltrado);
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  getClientes(generalFiltrado){
    this._crmService.buscarClientes().subscribe(
      result => {
        let contador=0;
        for(let i=0; i<result.length; i++){
          for(let j=0; j<generalFiltrado.length; j++){
            if(result[i].idCliente == generalFiltrado[j].idCliente){
              this.clientes[contador]=result[i];
              contador++;
            }
          }
        }
        this.cliente=this.clientes[0];
        this.getFechas(generalFiltrado)
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  getFechas(generalFiltrado){
    this._crmService.buscarFechas().subscribe(
      result => {
        let contador=0;
        for(let i=0; i<result.length; i++){
          for(let j=0; j<generalFiltrado.length; j++){
            if(result[i].idFecha == generalFiltrado[j].fechas){
              this.fechas[contador]=result[i];
              contador++;
            }
          }
        }
        this.fecha=this.fechas[0];
        this.getConsumos(generalFiltrado);
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  getConsumos(generalFiltrado){
    this._crmService.buscarConsumo().subscribe(
      result => {
        let contador=0;
        for(let i=0; i<result.length; i++){
          for(let j=0; j<generalFiltrado.length; j++){
            if(result[i].idConsumo == generalFiltrado[j].consumo){
              this.consumos[contador]=result[i];
              contador++;
            }
          }
        }
        this.getPotencias(generalFiltrado);
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  getPotencias(generalFiltrado){
    this._crmService.buscarPotencia().subscribe(
      result => {
        let contador=0;
        for(let i=0; i<result.length; i++){
          for(let j=0; j<generalFiltrado.length; j++){
            if(result[i].idPotencia == generalFiltrado[j].potencia){
              this.potencias[contador]=result[i];
              contador++;
            }
          }
        }
        this.getPrecioPotencias(generalFiltrado);
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  getPrecioPotencias(generalFiltrado){
    this._crmService.buscarPotenciaConsumida().subscribe(
      result => {
        let contador=0;
        for(let i=0; i<result.length; i++){
          for(let j=0; j<generalFiltrado.length; j++){
            if(result[i].idPotencia_consumida == generalFiltrado[j].potencia_consumida){
              this.potenciasConsumidas[contador]=result[i];
              contador++;
            }
          }
        }
        this.getPrecios(generalFiltrado);
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  getPrecios(generalFiltrado){
    this._crmService.buscarPrecio().subscribe(
      result => {
        let contador=0;
        for(let i=0; i<result.length; i++){
          for(let j=0; j<generalFiltrado.length; j++){
            if(result[i].idPrecio == generalFiltrado[j].precio){
              this.precios[contador]=result[i];
              let precio:number = +(this.precios[contador].cuota_fija);
              this.cuotaFinal = +(this.cuotaFinal+precio);
              contador++;
            }
          }
        }
        this.ivaFinal = this.cuotaFinal*0.21;
        this.importeFinal = this.cuotaFinal+this.ivaFinal;

        this.getVarios(generalFiltrado);
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  getVarios(generalFiltrado){
    this._crmService.buscarVarios().subscribe(
      result => {
        let contador=0;
        for(let i=0; i<result.length; i++){
          for(let j=0; j<generalFiltrado.length; j++){
            if(result[i].idVarios == generalFiltrado[j].varios){
              this.variosAll[contador]=result[i];
              contador++;
            }
          }
        }
        this.pieChartData = [this.consumos[0].consumo1, this.consumos[0].consumo2, this.consumos[0].consumo3, this.consumos[0].consumo4, this.consumos[0].consumo5, this.consumos[0].consumo6];
        this.consumoPorDia();
      },
      error => {
        console.log(<any>error);
      }
    );
  }


  consumoPorDia() {
    for(let i=0; i<this.consumos.length; i++){
      let total = +(this.consumos[i].consumoTotal / this.fechas[i].dias);
      this.consumoDiario[i] = {
        id : this.fechas[i].idFecha,
        consumo : total
      }
    }
  }

  //codigo necesario para el funcionamiento de la grafica circular
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'left',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };

  public pieChartLabels: Label[] = [['1'], ['2'], ['3'], ['4'], ['5'], ['6']];
  public pieChartData: number[] = [0, 0, 0, 0, 0, 0];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(18, 195, 216,0.3)', 'rgba(219, 219, 219,0.3)', 'rgba(216, 210, 18,0.3)', 'rgba(172, 115, 185,0.3)', 'rgba(21, 191, 57,0.3)'],
    },
  ];

  ngOnInit(): void {
    this.getGeneral();
  }

}
