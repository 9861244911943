<div class="container" id="pdf">
    <!--------------------------------------------------------------------------------------------------------->
    <div class="row separacion">
        <div class="logo col-4">
            <img src="assets/media/logoMenor.png" alt="" class="img-fluid w-75">
        </div>
        <table class="table col-8 tablaFecha" *ngFor="let g of general">
            <tbody *ngFor="let fe of fecha">
                <tr>
                    <th class="text-center fondo pt-1 pb-0">Nº FACTURA</th>
                    <td class="pb-0 pt-1">
                        <div class="d-flex d-flex justify-content-end">
                            <ng-container *ngFor="let c of cliente">
                                {{c.codigo}}
                            </ng-container> - {{g.numero_factura}}
                        </div>
                    </td>
                </tr>
                <tr>
                    <th class="text-center fondo pt-1 pb-0">FECHA FACTURA</th>
                    <td class="pb-0 pt-1">
                        <div class="d-flex d-flex justify-content-end">{{fe.fecha_factura}}</div>
                    </td>
                </tr>
                <tr>
                    <th class="text-center fondo pt-1 pb-0">FECHA CARGO</th>
                    <td class="pb-0 pt-1">
                        <div class="d-flex d-flex justify-content-end">{{fe.fecha_cargo}}</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!--------------------------------------------------------------------------------------------------------->
    <div class="row my-3">
        <div class="card cardTop col-5 pl-0">
            <div class="card-header fondoHeader p-1 pb-0">
                <h5 class="card-title text-center tituloCard p-0 mb-0">DATOS SOCIALES</h5>
            </div>
            <div class="card-body fondo pb-0 pt-1">
                <p class="card-text text-center textoCarta pb-0 mb-0">Grupo I.R. RURAL, S.L. B-88054853</p>
                <p class="card-text text-center textoCarta pb-0 mb-0">Calle de la Granja 82, Bajo Derecha</p>
                <p class="card-text text-center textoCarta pb-0 mb-0">28108 Alcobendas</p>
                <p class="card-text text-center textoCarta pb-0 mb-0">Madrid</p>
            </div>
        </div>

        <div class="card cardTop col-6 offset-1">
            <div class="card-header fondoHeader p-1 pb-0">
                <h5 class="card-title text-center tituloCard p-0 mb-0">DATOS CLIENTE</h5>
            </div>
            <div class="card-body pb-0 pt-1" *ngFor="let c of cliente">
                <p class="card-text text-center textoCarta pb-0 mb-0">{{c.nombre}}</p>
                <ng-container *ngFor="let f of factura">
                    <p class="card-text text-center textoCarta pb-0 mb-0">{{f.calle}}</p>
                    <p class="card-text text-center textoCarta pb-0 mb-0">{{c.codigo_postal}},{{c.ciudad}}</p>
                </ng-container>
                <p class="card-text text-center textoCarta pb-0 mb-0">{{c.Cif}}</p>
            </div>
        </div>
    </div>
    <!--------------------------------------------------------------------------------------------------------->
    <div class="row margen">
        <table class="table col-12 tablaFecha1" *ngFor="let fe of fecha">
            <tbody>
                <tr>
                    <th class="fondoPeriodo w-25 textoPeriodo text-center pt-1 pb-0">
                        <h5>PERIODO FACTURADO</h5>
                    </th>
                    <td class="pb-0 pt-1">
                        <div class="d-flex justify-content-end text-dark">
                            <!-- De verdad juro que soy incapaz de que al imprimir el pdf se separen las palabras a continuación, así que toma arreglo pocho -->
                            <span class="arregloPocho">{{fe.periodo_inicio}}</span><span class="arregloPocho">al</span><span class="arregloPocho">{{fe.periodo_final}}</span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!--------------------------------------------------------------------------------------------------------->
    <div class="row mt-3" *ngFor="let p of precio">
        <div class="col-12 bordeBajo">
            <div class="row">
                <div class="h5 d-flex textoColor">CONCEPTO</div>
                <div class="h5 d-flex ml-auto textoColor">IMPORTE</div>
            </div>
        </div>
        <div class="col-12 bordeBajo">
            <div class="row">
                <!-- De verdad juro que soy incapaz de que al imprimir el pdf se separen las palabras a continuación, así que toma arreglo pocho -->
                <div class="d-flex py-2 textoCarta"><span class="arregloPocho">SERVICIO</span><span class="arregloPocho">DE</span><span class="arregloPocho">GESTIÓN</span><span class="arregloPocho">ENERGÉTICA</span></div>
                <div class="d-flex ml-auto py-2 textoCarta">{{p.cuota_fija}} &#8364;</div>
            </div>
        </div>
        <div class="col-12 bordeBajoMayor">
            <div class="row py-2">
                <div class="d-flex py-2 textoCarta"></div>
            </div>
        </div>
        <div class="col-6 offset-6 pr-0">
            <table class="table tablaPrecio">
                <tbody>
                    <tr>
                        <th class="fondo pt-1 pb-0">
                            <div class="textoCarta text-center">SUBTOTAL</div>
                        </th>
                        <td class="pt-1 pb-0">
                            <div class="d-flex d-flex justify-content-end textoCarta">{{p.cuota_fija}} &#8364;</div>
                        </td>
                    </tr>
                    <tr>
                        <th class="fondo pt-1 pb-0">
                            <div class="textoCarta text-center">IVA (21%)</div>
                        </th>
                        <td class="pt-1 pb-0">
                            <div class="d-flex d-flex justify-content-end textoCarta">{{precioIVa}} &#8364;</div>
                        </td>
                    </tr>
                    <tr>
                        <th class="fondo pt-1 pb-0">
                            <div class="textoCarta text-center">TOTAL FACTURA</div>
                        </th>
                        <td class="pt-1 pb-0">
                            <div class="d-flex d-flex justify-content-end textoCarta">{{p.precioTotal}} &#8364;</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!--------------------------------------------------------------------------------------------------------->
    <div class="row mt-2">
        <div class="h4 textoColor col-12 pl-0">EFICIENCIA ENERGÉTICA</div>
        <div class="h6 col-12 pl-0">Nos adaptamos a todos los sectores para optimizar el consumo.</div>
        <div class="h6 col-12 pl-0">Grupo I.R. ofrece a sus clientes soluciones llave en mano.</div>
    </div>
    <!--------------------------------------------------------------------------------------------------------->
    <div class="row mt-5">
        <!-- <div class="card col-3 fondoPeriodo textoColorCard">
            <div class="card-body pb-1 pt-0 pr-0 pl-0">
                <h6 class="card-title text-center">ANÁLISIS RECURSOS ENERGÉTICOS</h6>
                <p class="card-text text-center">Optimización de los procesos productivos y del uso energético</p>
            </div>
        </div>

        <div class="card col-3 fondoPeriodo textoColorCard">
            <div class="card-body pb-1 pt-1 pr-0 pl-0">
                <h6 class="card-title text-center">MODELO E.S.E.</h6>
                <p class="card-text text-center">Presentación de resultados. Soluciones racionales y económicas particularizadas para cada cliente.</p>
            </div>
        </div>

        <div class="card col-3 fondoPeriodo textoColorCard">
            <div class="card-body pb-0 pt-2 pr-0 pl-0">
                <h6 class="card-title text-center">LLAVE EN MANO</h6>
                <p class="card-text text-center">Puesta en marcha del proyecto aceptado</p>
            </div>
        </div>

        <div class="card col-3 fondoPeriodo textoColorCard">
            <div class="card-body pb-1 pt-0 pr-0 pl-0">
                <h6 class="card-title text-center">GESTIÓN INTEGRAL SERVICIOS ENERGÉTICOS</h6>
                <p class="card-text text-center">La contratación de una E.S.E lleva implícitas una serie de ventajas técnicas, económicas y ambientales.
                </p>
            </div>
        </div> -->

        <div class="card cardTop col-3">
            <div class="card-header fondoHeader p-1 pb-0">
                <h5 class="card-title text-center tituloCard p-0 mb-0">ANÁLISIS RECURSOS ENERGÉTICOS</h5>
            </div>
            <div class="card-body pb-0 pt-1">
                <p class="card-text text-center textoCarta pb-0 mb-0">Optimización de los procesos productivos y del uso energético</p>
            </div>
        </div>
        <div class="card cardTop col-3">
            <div class="card-header fondoHeader p-1 pb-0">
                <h5 class="card-title text-center tituloCard p-0 mb-0">MODELO E.S.E.</h5>
            </div>
            <div class="card-body pb-0 pt-1">
                <p class="card-text text-center textoCarta pb-0 mb-0">Presentación de resultados. Soluciones racionales y económicas particularizadas para cada cliente.</p>
            </div>
        </div>
        <div class="card cardTop col-3">
            <div class="card-header fondoHeader p-1 pb-0">
                <h5 class="card-title text-center tituloCard p-0 mb-0">LLAVE EN MANO</h5>
            </div>
            <div class="card-body pb-0 pt-1">
                <p class="card-text text-center textoCarta pb-0 mb-0">Puesta en marcha del proyecto aceptado</p>
            </div>
        </div>
        <div class="card cardTop col-3">
            <div class="card-header fondoHeader p-1 pb-0">
                <h5 class="card-title text-center tituloCard p-0 mb-0">GESTIÓN INTEGRAL SERVICIOS ENERGÉTICOS</h5>
            </div>
            <div class="card-body pb-0 pt-1">
                <p class="card-text text-center textoCarta pb-0 mb-0">La contratación de una E.S.E lleva implícitas una serie de ventajas técnicas, económicas y ambientales.</p>
            </div>
        </div>

    </div>
    <!--------------------------------------------------------------------------------------------------------->
    <div class="row mt-1">
        <table class="table col-12 tablaFecha1">
            <tbody>
                <tr>
                    <th class="fondoPeriodo w-25 textoPeriodo pt-1 pb-5">
                        <h6 class="pl-2">RECOMENDACIÓN I.R.</h6>
                    </th>
                    <td class="pb-5 pt-1">
                        <div class="h6">Optimice los consumos obteniendo mayor calidad de vida.</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!--------------------------------------------------------------------------------------------------------->
    <div class="row margen3">
        <div class="h4 textoColor col-12 pl-0">RESPONSABILIDAD SOCIAL</div>
        <div class="col-12 pl-0">
            <img src="assets/media/energiaRenovable.png" class="imagenTop">
        </div>
        <div class="col-4 pl-0">
            <img src="assets/media/asosiacionEmpresas.png" alt="" class="img-fluid">
        </div>
        <div class="col-6 offset-1">
            <img src="assets/media/idae.jpg" alt="" class="segundaImagen">
        </div>
    </div>
    <!--------------------------------------------------------------------------------------------------------->
    <div class="row mt-3">
        <p class="textoParrafo col-12 text-center">Más información: Lunes a Viernes 09:00 - 18:00 - 98 351 30 04 -administracion@grupoirr.com -www.grupoirr.com</p>
        <small class="text-center col-12 text-dark" id="texto">Grupo I.R. Rural S.L. inscrita en el Registro Mercantil
            de Madrid,Tomo 37546, Folio 160, Sección 8, Hoja M66279, Inscripción 1.</small>
    </div>
    <!--------------------------------------------------------------------------------------------------------->
    <div class="row margenLogo">
        <img src="assets/media/logo.jpg" alt="" class="img-fluid">
    </div>
    <!--------------------------------------------------------------------------------------------------------->
    <div class="row margenTextoSegundo">
        <small class="text-center col-12 text-dark html2pdf_page-break">Grupo I.R. Rural S.L. inscrita en el Registro
            Mercantil
            de Madrid,Tomo 37546, Folio 160, Sección 8, Hoja M66279, Inscripción 1.</small>
    </div>
    <!--------------------------------------------------------------------------------------------------------->
    <div class="row mt-25">
        <div class="logo col-4">
            <img src="assets/media/logoMenor.png" alt="" class="img-fluid w-100">
        </div>
        <table class="table col-8 tablaFecha" *ngFor="let c of cliente">
            <tbody>
                <tr>
                    <th class="text-center fondo pt-1 pb-0">CUPS</th>
                    <td class="pb-0 pt-1">
                        <div class="d-flex d-flex justify-content-end">{{c.cups}}</div>
                    </td>
                </tr>
                <tr>
                    <th class="text-center fondo pt-1 pb-0">SUMINISTRO</th>
                    <td class="pb-0 pt-1">
                        <div class="d-flex d-flex justify-content-end">{{c.calle}}, {{c.portal}}, {{c.piso}}{{c.letra}}
                        </div>
                    </td>
                </tr>
                <tr>
                    <th class="text-center fondo pt-1 pb-0">TARIFA</th>
                    <td class="pb-0 pt-1">
                        <div class="d-flex d-flex justify-content-end">{{c.tarifa}}</div>
                    </td>
                </tr>
                <tr>
                    <th class="text-center fondo pt-1 pb-0">COSTE ENERGETICO</th>
                    <td class="pb-0 pt-1" *ngFor="let p of precio">
                        <div class="d-flex d-flex justify-content-end">{{p.cuota_fija}} &#8364;</div>
                    </td>
                </tr>
            </tbody>
        </table>

        <table class="table col-12 tablaFecha1" *ngFor="let fe of fecha">
            <tbody>
                <tr>
                    <th class="fondoPeriodo w-25 textoPeriodo text-center pt-1 pb-0">
                        <h6>PERIODO FACTURADO</h6>
                    </th>
                    <td class="pb-0 pt-1">
                        <div class="d-flex justify-content-end text-dark">
                            <!-- De verdad juro que soy incapaz de que al imprimir el pdf se separen las palabras a continuación, así que toma arreglo pocho -->
                            <span class="arregloPocho">{{fe.periodo_inicio}}</span><span class="arregloPocho">al</span><span class="arregloPocho">{{fe.periodo_final}}</span>
                        </div>

                        <!-- <div class="d-flex d-flex justify-content-end">{{fe.periodo_inicio}} al {{fe.periodo_final}}
                        </div> -->
                    </td>
                </tr>
            </tbody>
        </table>

        <p class="h6 col-12 pl-0 mb-2">Las compañías Distribuidoras, dividen el coste de la energía en períodos. Estos períodos se dividen en horas y meses.Tarifas 3.0TD, 6.1 TD</p>

        <table class="table col-5 tablaEnergia">
            <tbody>
                <tr>
                    <th class="fondoPeriodo textoPeriodo text-center pt-1 pb-0">
                        <h6>PERIODIFICACIÓN DE LA ENERGÍA</h6>
                    </th>
                </tr>
            </tbody>
        </table>
    </div>
    <!--------------------------------------------------------------------------------------------------------->
    <div class="row">
        <img src="assets/media/graficaHorarios.png" alt="" class="imagenPersonalizada">
    </div>
    <!--------------------------------------------------------------------------------------------------------->
    <div class="row">
        <div class="col-4">
            <h5 class="text-center">Temporadas eléctricas</h5>
            <table class="table bordesTabla">
                <thead class="fondoTabla">
                    <tr>
                        <th scope="col" class="text-dark">Meses</th>
                        <th scope="col" class="text-dark">Península</th>
                    </tr>
                </thead>
                <tbody class="textoTabla">
                    <tr>
                        <td>Enero</td>
                        <td class="text-danger text-center">Alta</td>
                    </tr>
                    <tr>
                        <td>Febrero</td>
                        <td class="text-danger text-center">Alta</td>
                    </tr>
                    <tr>
                        <td>Marzo</td>
                        <td class="text-warning text-center">Media Alta</td>
                    </tr>
                    <tr>
                        <td>Abril</td>
                        <td class="text-info text-center">Baja</td>
                    </tr>
                    <tr>
                        <td>Mayo</td>
                        <td class="text-info text-center">Baja</td>
                    </tr>
                    <tr>
                        <td>Junio</td>
                        <td class="text-success text-center">Media</td>
                    </tr>
                    <tr>
                        <td>Julio</td>
                        <td class="text-danger text-center">Alta</td>
                    </tr>
                    <tr>
                        <td>Agosto</td>
                        <td class="text-success text-center">Media</td>
                    </tr>
                    <tr>
                        <td>Septiembre</td>
                        <td class="text-success text-center">Media</td>
                    </tr>
                    <tr>
                        <td>Octubre</td>
                        <td class="text-info text-center">Baja</td>
                    </tr>
                    <tr>
                        <td>Noviembre</td>
                        <td class="text-warning text-center">Media Alta</td>
                    </tr>
                    <tr>
                        <td>Diciembre</td>
                        <td class="text-danger text-center">Alta</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-7 offset-1">
            <table class="table bordesTabla mt-20">
                <tbody>
                    <tr>
                        <td>A=Alta</td>
                        <td>Lunes a viernes laborables de temporada alta</td>
                    </tr>
                    <tr>
                        <td>B= Media Alta</td>
                        <td>Lunes a viernes laborables de temporada media-alta</td>
                    </tr>
                    <tr>
                        <td>B1= Media</td>
                        <td>Lunes a viernes laborables de temporada media</td>
                    </tr>
                    <tr>
                        <td>C= Baja</td>
                        <td>Lunes a viernes laborables de temporada baja</td>
                    </tr>
                    <tr>
                        <td>D= Festivos</td>
                        <td>Sábados, domingos y festivos nacionales</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!--------------------------------------------------------------------------------------------------------->
    <div class="row mt-3">
        <p class="textoParrafo col-12 text-center">Más información: Lunes a Viernes 09:00 - 18:00 - 98 351 30 04 -administracion@grupoirr.com -www.grupoirr.com</p>
        <small class="text-center col-12 text-dark" id="texto">Grupo I.R. Rural S.L. inscrita en el Registro Mercantil
            de Madrid,Tomo 37546, Folio 160, Sección 8, Hoja M66279, Inscripción 1.</small>
    </div>

    <!--------------------------------------------------------------------------------------------------------->
    <div class="row mt-25">
        <div class="logo col-5">
            <img src="assets/media/logoMenor.png" alt="" class="img-fluid w-100">
        </div>

        <div class="col-7">
            <div class="row">
                <table class="table col-12 tablaEnergia">
                    <tbody>
                        <tr>
                            <th class="fondoPeriodo text-dark text-center p-2">
                                <h6>INFORME MENSUAL</h6>
                            </th>
                        </tr>
                    </tbody>
                </table>

                <table class="table col-12 tablaFecha">
                    <tbody *ngFor="let c of cliente">
                        <tr>
                            <th class="text-center fondo pt-1 pb-0">CUPS</th>
                            <td class="pb-0 pt-1">
                                <div class="d-flex d-flex justify-content-end">{{c.cups}}</div>
                            </td>
                        </tr>
                        <tr>
                            <th class="text-center fondo pt-1 pb-0">SUMINISTRO</th>
                            <td class="pb-0 pt-1">
                                <div class="d-flex d-flex justify-content-end">{{c.calle}}, {{c.portal}}, {{c.piso}}{{c.letra}}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="text-center fondo pt-1 pb-0">TARIFA</th>
                            <td class="pb-0 pt-1">
                                <div class="d-flex d-flex justify-content-end">{{c.tarifa}}</div>
                            </td>
                        </tr>
                        <tr *ngFor="let p of precio">
                            <th class="text-center fondo pt-1 pb-0">COSTE ENERGETICO</th>
                            <td class="pb-0 pt-1">
                                <div class="d-flex d-flex justify-content-end">{{p.cuota_fija}} &#8364;</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <table class="table col-12 tablaFecha1" *ngFor="let fe of fecha">
            <tbody>
                <tr>
                    <th class="fondoPeriodo w-25 text-dark text-center pt-1 pb-0">
                        <h6>PERIODO FACTURADO</h6>
                    </th>
                    <td class="pb-0 pt-1">
                        <div class="d-flex justify-content-end text-dark">
                            <!-- De verdad juro que soy incapaz de que al imprimir el pdf se separen las palabras a continuación, así que toma arreglo pocho -->
                            <span class="arregloPocho">{{fe.periodo_inicio}}</span><span class="arregloPocho">al</span><span class="arregloPocho">{{fe.periodo_final}}</span>
                        </div>

                        <!-- <div class="d-flex d-flex justify-content-end arregloPocho">{{fe.periodo_inicio}} <span class="arregloPocho">al</span> {{fe.periodo_final}}
                        </div> -->
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!--------------------------------------------------------------------------------------------------------->
    <div class="row">
        <div class="col-4">
            <div class="row">
                <table class="table col-12 tablaConsumo">
                    <thead class="fondoPeriodo">
                        <tr>
                            <th scope="col" class="text-dark p-0 pl-1" colspan="2">ANÁLISIS DE CONSUMO</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th class="text-center fondo pt-1 pb-0">CONSUMO DIARIO kWh</th>
                            <td class="pb-0 pt-1">
                                <div class="d-flex d-flex justify-content-end">{{consumoDiario}}</div>
                            </td>
                        </tr>
                        <tr *ngFor="let fec of fecha">
                            <th class="text-center fondo pt-1 pb-0">DÍAS DE FACTURACIÓN</th>
                            <td class="pb-0 pt-1">
                                <div class="d-flex d-flex justify-content-end">{{fec.dias}}</div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table class="table bordesTabla col-8">
                    <thead>
                        <tr class="fondoPeriodo">
                            <th scope="col" class="text-dark" colspan="2">Energía consumida kWh</th>
                        </tr>
                        <tr class="fondo">
                            <th scope="col" class="text-dark">PERIODO</th>
                            <th scope="col" class="text-dark">kWh</th>
                        </tr>
                    </thead>
                    <tbody class="textoTabla" *ngFor="let con of consumo">
                        <tr>
                            <td class="bg-light p-0 pl-1">PERIODO 1</td>
                            <td class="text-center p-0">{{con.consumo1}}</td>
                        </tr>
                        <tr>
                            <td class="bg-light p-0 pl-1">PERIODO 2</td>
                            <td class="text-center p-0">{{con.consumo2}}</td>
                        </tr>
                        <tr>
                            <td class="bg-light p-0 pl-1">PERIODO 3</td>
                            <td class="text-center p-0">{{con.consumo3}}</td>
                        </tr>
                        <tr>
                            <td class="bg-light p-0 pl-1">PERIODO 4</td>
                            <td class="text-center p-0">{{con.consumo4}}</td>
                        </tr>
                        <tr>
                            <td class="bg-light p-0 pl-1">PERIODO 5</td>
                            <td class="text-center p-0">{{con.consumo5}}</td>
                        </tr>
                        <tr>
                            <td class="bg-light p-0 pl-1">PERIODO 6</td>
                            <td class="text-center p-0">{{con.consumo6}}</td>
                        </tr>
                        <tr>
                            <td class="bg-light p-0 pl-1">TOTAL kwh</td>
                            <td class="text-center p-0">{{con.consumoTotal}}</td>
                        </tr>
                    </tbody>
                </table>

                <table class="table bordesTabla col-8">
                    <thead>
                        <tr class="fondoPeriodo">
                            <th scope="col" class="text-dark" colspan="2">POTENCIA</th>
                        </tr>
                        <tr class="fondo">
                            <th scope="col" class="text-dark">PERIODO</th>
                            <th scope="col" class="text-dark">CONTRATO</th>
                        </tr>
                    </thead>
                    <tbody class="textoTabla" *ngFor="let pot of potencia">
                        <tr>
                            <td class="bg-light p-0 pl-1">PERIODO 1</td>
                            <td class="text-center p-0">{{pot.potencia1}}</td>
                            <!-- <td class="text-center p-0">{{pot.potencia1 | number: '.1-2'}}</td> -->
                        </tr>
                        <tr>
                            <td class="bg-light p-0 pl-1">PERIODO 2</td>
                            <td class="text-center p-0">{{pot.potencia2}}</td>
                        </tr>
                        <tr>
                            <td class="bg-light p-0 pl-1">PERIODO 3</td>
                            <td class="text-center p-0">{{pot.potencia3}}</td>
                        </tr>
                        <tr>
                            <td class="bg-light p-0 pl-1">PERIODO 4</td>
                            <td class="text-center p-0">{{pot.potencia4}}</td>
                        </tr>
                        <tr>
                            <td class="bg-light p-0 pl-1">PERIODO 5</td>
                            <td class="text-center p-0">{{pot.potencia5}}</td>
                        </tr>
                        <tr>
                            <td class="bg-light p-0 pl-1">PERIODO 6</td>
                            <td class="text-center p-0">{{pot.potencia6}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="chart mt-20 col-5">
            <h5 class="text-center">CONSUMO POR PERÍODOS kWh</h5>
            <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins" [colors]="pieChartColors" [legend]="pieChartLegend">
            </canvas>
        </div>


        <table class="table bordesTabla col-6" *ngFor="let v of varios">
            <tbody>
                <tr>
                    <td class="text-dark">ENERGIA REACTIVA</td>
                    <td>{{v.reactiva}} &#8364;</td>
                </tr>
                <tr>
                    <td class="text-dark">TOTAL POTENCIA</td>
                    <td>{{v.potencia_total_euros}} &#8364;</td>
                </tr>
                <tr>
                    <td class="text-dark">EXCESOS DE POTENCIA</td>
                    <td>{{v.exc_potencia}} &#8364;</td>
                </tr>
                <tr>
                    <td class="text-dark">ALQUILER EQUIPOS DE MEDIDA</td>
                    <td>{{v.contador_precio}} &#8364;</td>
                </tr>
                <tr>
                    <td class="text-dark">Otros conceptos</td>
                    <td>{{v.otros}} &#8364;</td>
                </tr>
                <tr>
                    <td class="text-dark">Impuestos sobre electricidad (5,11%)</td>
                    <td>{{v.iee}} &#8364;</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="row">
        <p class="textoMaximetro col-12 p-1">El maxímetro es la potencia máxima demanda durante al menos 15 minutos en el periodo de facturación, si se produce excesos de potencia implica incrementos de coste.</p>
    </div>

    <div class="row">
        <p class="textoParrafo col-12 text-center mb-2">Más información: Lunes a Viernes 09:00 - 18:00 - 98 351 30 04 -administracion@grupoirr.com -www.grupoirr.com</p>
        <small class="text-center col-12 text-dark" id="texto">Grupo I.R. Rural S.L. inscrita en el Registro Mercantil
            de Madrid,Tomo 37546, Folio 160, Sección 8, Hoja M66279, Inscripción 1.</small>
    </div>
</div>

<!--------------------------------------------------------------------------------------------------------->
<div class="container">
    <div class="row my-5">
        <button type="button" (click)="exportPdf()" class="btn btn-danger col-4">EXPORTAR A PDF</button>
        <button type="button" class="btn btn-danger col-4 ml-5" [routerLink]="['/facturas/facturas']">VOLVER A LA
            LISTA DE FACTURAS</button>
    </div>
</div>