import { Component, OnInit } from '@angular/core';
import * as html2pdf from 'html2pdf.js';
import { ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { GeneralService } from '../services/general.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

//models
import { Cliente } from 'src/app/models/cliente';
import { Facturacion } from 'src/app/models/facturacion';
import { PotenciaConsumida } from 'src/app/models/potenciaConsumida';
import { Consumo } from 'src/app/models/consumo';
import { Potencia } from 'src/app/models/potencia';
import { Varios } from 'src/app/models/varios';
import { Fechas } from 'src/app/models/fechas';
import { Precio } from 'src/app/models/precio';
import { General } from 'src/app/models/general';
import { Tarifas } from 'src/app/models/tarifas';
import { consumoDiario } from '../models/consumoTotal';


@Component({
  selector: 'app-exportar-pdf-multiple',
  templateUrl: './exportar-pdf-multiple.component.html',
  styleUrls: ['./exportar-pdf-multiple.component.scss'],
  providers: [GeneralService]
})
export class ExportarPdfMultipleComponent implements OnInit {

  public cantidadFacturas = 3;
  public arrayIds = [1,2,3];

    //general
    public general: General;
    public generales:Array <General>=[];
    public numeroFactura;
    //cliente
    public cliente: Cliente;
    public clientes:Array <Cliente>=[];
    //factura
    public factura: Facturacion;
    public facturas:Array <Facturacion>=[];
    //fecha
    public fecha: Fechas;
    public fechas=[];
    // public fechas:Array<Fechas>=[];
    //precio
    public precio: Precio;
    public precios =[];
    // public precios:Array<Precio> =[];
    //consumo
    public consumo: Consumo;
    public consumos=[];
    // public consumos:Array<Consumo>=[];
    //potencia
    public potencia: Potencia;
    public potencias=[];
    // public potencias:Array<Potencia>=[];
    //precioPotencia
    public potenciaConsumida: PotenciaConsumida;
    public potenciasConsumidas:Array<PotenciaConsumida>=[];
    //varios
    public varios: Varios;
    public variosAll=[];
    // public variosAll:Array<Varios>=[];
    public precioIVa = [];
    public consumoDiario:Array<consumoDiario>=[];
    //precios
    public cuotaFinal:number = 0;
    public ivaFinal:number = 0;
    public importeFinal:number = 0;

  constructor(private _route: ActivatedRoute, private _router: Router, private _crmService: GeneralService) { }

  numberWithSeparator(x) {
    return parseFloat(x).toLocaleString('de-DE', {minimumFractionDigits: 2});
    // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  exportPdf() {

    for (let i = 0; i < this.cantidadFacturas; i++) {

      const options = {
        filename: "FACTURA.pdf",
        imagen: { type: 'jpeg', quality: 0.98 },
        html2canvas: {},
        margin: [0, 10, 0, 10],
        jsPDF: { format: 'a4', orientation: 'portrait' }
      };

      const content: Element = document.getElementById('pdf'+i);

      html2pdf().from(content).set(options).save();

    }
  }

  //eventos para comprobar el funcionamiento de la grafica circular
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  getGeneral() {
    this._crmService.buscarGeneral().subscribe(
      result => {
        let ids = JSON.parse(localStorage.getItem('arrayIdsMultiple'));
        console.log(ids);

        this.arrayIds = ids;
        this.cantidadFacturas = ids.length;

        let contador=0;
        for(let j=0; j<ids.length; j++){
          for(let i=0; i<result.length; i++){
            if(result[i].idGeneral == ids[j]){
              this.generales[contador] = result[i];
              this.numeroFactura = this.generales[contador].numero_factura;
              contador++;
            }
          }
        }
        this.getFacturas(this.generales);
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  getFacturas(generalFiltrado){
    this._crmService.buscarFacturacion().subscribe(
      result => {
        let contador=0;
        for(let j=0; j<generalFiltrado.length; j++){
          for(let i=0; i<result.length; i++){
            if(result[i].idFactura == generalFiltrado[j].factura){
              this.facturas[contador]=result[i];
              contador++;
            }
          }
        }
        this.getClientes(generalFiltrado);
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  getClientes(generalFiltrado){
    this._crmService.buscarClientes().subscribe(
      result => {
        let contador=0;
        for(let j=0; j<generalFiltrado.length; j++){
          for(let i=0; i<result.length; i++){
            if(result[i].idCliente == generalFiltrado[j].idCliente){
              this.clientes[contador]=result[i];
              contador++;
            }
          }
        }
        this.getFechas(generalFiltrado);
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  getFechas(generalFiltrado){
    this._crmService.buscarFechas().subscribe(
      result => {
        let contador=0;
        for(let j=0; j<generalFiltrado.length; j++){
          for(let i=0; i<result.length; i++){
            if(result[i].idFecha == generalFiltrado[j].fechas){
              this.fechas[contador]=result[i];

              this.fechas[contador].fecha_factura = this.fechas[contador].fecha_factura.slice(8,10) + '/' + this.fechas[contador].fecha_factura.slice(5,7) + '/' + this.fechas[contador].fecha_factura.slice(0,4);
              this.fechas[contador].fecha_cargo = this.fechas[contador].fecha_cargo.slice(8,10) + '/' + this.fechas[contador].fecha_cargo.slice(5,7) + '/' + this.fechas[contador].fecha_cargo.slice(0,4);
              this.fechas[contador].periodo_final = this.fechas[contador].periodo_final.slice(8,10) + '/' + this.fechas[contador].periodo_final.slice(5,7) + '/' + this.fechas[contador].periodo_final.slice(0,4);
              this.fechas[contador].periodo_inicio = this.fechas[contador].periodo_inicio.slice(8,10) + '/' + this.fechas[contador].periodo_inicio.slice(5,7) + '/' + this.fechas[contador].periodo_inicio.slice(0,4);
      

              contador++;
            }
          }
        }
        this.getConsumos(generalFiltrado);
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  getConsumos(generalFiltrado){
    this._crmService.buscarConsumo().subscribe(
      result => {
        let contador=0;
        for(let j=0; j<generalFiltrado.length; j++){
          for(let i=0; i<result.length; i++){
            if(result[i].idConsumo == generalFiltrado[j].consumo){
              this.consumos[contador]=result[i];
              this.pieChartData[contador] = [+(this.consumos[contador].consumo1*100)/this.consumos[contador].consumoTotal,
                    +(this.consumos[contador].consumo2*100)/this.consumos[contador].consumoTotal, 
                    +(this.consumos[contador].consumo3*100)/this.consumos[contador].consumoTotal, 
                    +(this.consumos[contador].consumo4*100)/this.consumos[contador].consumoTotal, 
                    +(this.consumos[contador].consumo5*100)/this.consumos[contador].consumoTotal, 
                    +(this.consumos[contador].consumo6*100)/this.consumos[contador].consumoTotal];
              contador++;
            }
          }
        }
        this.getPotencias(generalFiltrado);
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  getPotencias(generalFiltrado){
    this._crmService.buscarPotencia().subscribe(
      result => {
        let contador=0;
        for(let j=0; j<generalFiltrado.length; j++){
          for(let i=0; i<result.length; i++){
            if(result[i].idPotencia == generalFiltrado[j].potencia){
              this.potencias[contador]=result[i];

              this.potencias[contador].potencia1 = this.numberWithSeparator(this.potencias[contador].potencia1);
              this.potencias[contador].potencia2 = this.numberWithSeparator(this.potencias[contador].potencia2);
              this.potencias[contador].potencia3 = this.numberWithSeparator(this.potencias[contador].potencia3);
              this.potencias[contador].potencia4 = this.numberWithSeparator(this.potencias[contador].potencia4);
              this.potencias[contador].potencia5 = this.numberWithSeparator(this.potencias[contador].potencia5);
              this.potencias[contador].potencia6 = this.numberWithSeparator(this.potencias[contador].potencia6);
      
      

              contador++;
            }
          }
        }
        this.getPrecioPotencias(generalFiltrado);
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  getPrecioPotencias(generalFiltrado){
    this._crmService.buscarPotenciaConsumida().subscribe(
      result => {
        let contador=0;
        for(let j=0; j<generalFiltrado.length; j++){
          for(let i=0; i<result.length; i++){
            if(result[i].idPotencia_consumida == generalFiltrado[j].potencia_consumida){
              this.potenciasConsumidas[contador]=result[i];
              contador++;
            }
          }
        }
        this.getPrecios(generalFiltrado);
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  getPrecios(generalFiltrado){
    this._crmService.buscarPrecio().subscribe(
      result => {
        let contador=0;
        for(let j=0; j<generalFiltrado.length; j++){
          for(let i=0; i<result.length; i++){
            if(result[i].idPrecio == generalFiltrado[j].precio){
              this.precios[contador]=result[i];
              let precio: number = +(this.precios[contador].cuota_fija);
              this.cuotaFinal = +(this.cuotaFinal + precio);
              this.precioIVa[contador] = ((this.precios[contador].cuota_fija * 21) / 100).toPrecision(4);

              this.precioIVa[contador] = this.numberWithSeparator(this.precioIVa[contador]);
              this.precios[contador].cuota_fija = this.numberWithSeparator(this.precios[contador].cuota_fija);
              this.precios[contador].precioTotal = this.numberWithSeparator(this.precios[contador].precioTotal);

              contador++;
            }
          }
        }
        this.ivaFinal = this.cuotaFinal*0.21;
        this.importeFinal = this.cuotaFinal+this.ivaFinal;

        // console.log(this.precios)

        this.getVarios(generalFiltrado);
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  getVarios(generalFiltrado){
    this._crmService.buscarVarios().subscribe(
      result => {
        let contador=0;
        for(let j=0; j<generalFiltrado.length; j++){
          for(let i=0; i<result.length; i++){
            if(result[i].idVarios == generalFiltrado[j].varios){
              this.variosAll[contador]=result[i];
              
              this.variosAll[contador].reactiva = this.numberWithSeparator(this.variosAll[contador].reactiva);
              this.variosAll[contador].potencia_total_euros = this.numberWithSeparator(this.variosAll[contador].potencia_total_euros);
              this.variosAll[contador].exc_potencia = this.numberWithSeparator(this.variosAll[contador].exc_potencia);
              this.variosAll[contador].contador_precio = this.numberWithSeparator(this.variosAll[contador].contador_precio);
              this.variosAll[contador].otros = this.numberWithSeparator(this.variosAll[contador].otros);
              this.variosAll[contador].iee = this.numberWithSeparator(this.variosAll[contador].iee);
      

              contador++;
            }
          }
        }
        // this.pieChartData = [this.consumos[0].consumo1, this.consumos[0].consumo2, this.consumos[0].consumo3, this.consumos[0].consumo4, this.consumos[0].consumo5, this.consumos[0].consumo6];
        this.consumoPorDia();
      },
      error => {
        console.log(<any>error);
      }
    );
  }


  consumoPorDia() {
    for(let i=0; i<this.consumos.length; i++){
      let total = +(this.consumos[i].consumoTotal / this.fechas[i].dias).toPrecision(3);

      this.consumos[i].consumo1 = this.numberWithSeparator(this.consumos[i].consumo1);
      this.consumos[i].consumo2 = this.numberWithSeparator(this.consumos[i].consumo2);
      this.consumos[i].consumo3 = this.numberWithSeparator(this.consumos[i].consumo3);
      this.consumos[i].consumo4 = this.numberWithSeparator(this.consumos[i].consumo4);
      this.consumos[i].consumo5 = this.numberWithSeparator(this.consumos[i].consumo5);
      this.consumos[i].consumo6 = this.numberWithSeparator(this.consumos[i].consumo6);
      this.consumos[i].consumoTotal = this.numberWithSeparator(this.consumos[i].consumoTotal);


      this.consumoDiario[i] = {
        id : this.fechas[i].idFecha,
        consumo : (total)
      }
    }
  }

  //codigo necesario para el funcionamiento de la grafica circular
  // public pieChartOptions: ChartOptions = {
  //   responsive: true,
  //   legend: {
  //     position: 'left',
  //   },
  //   plugins: {
  //     datalabels: {
  //       formatter: (value, ctx) => {
  //         const label = ctx.chart.data.labels[ctx.dataIndex];
  //         return label;
  //       },
  //     },
  //   }
  // };
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'left',
    },
    plugins: {
      datalabels: {
        formatter: (dato) => 
        dato.toFixed(0) + "%",
        color: "#000"
      }
    }
  };

  public pieChartLabels: Label[] = [['1'], ['2'], ['3'], ['4'], ['5'], ['6']];
  // public pieChartData: number[] = [0, 0, 0, 0, 0, 0];
  public pieChartData= [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(18, 195, 216,0.3)', 'rgba(219, 219, 219,0.3)', 'rgba(216, 210, 18,0.3)', 'rgba(172, 115, 185,0.3)', 'rgba(21, 191, 57,0.3)'],
    },
  ];

  ngOnInit(): void {
    this.getGeneral();
  }

}
